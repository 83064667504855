import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function GetADemo() {
  return (
    <Layout
      title="Webinar: Elevate Workflows With AI For Enhanced Customer Support"
      keywords="IrisAgent, webinar, incidents, tickets"
      description="Watch our webinar: Optimize product-engineering-support workflows with AI-powered customer support. Real-time context, fewer escalations and enhanced customer experience!"
    >
      <Helmet>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link rel="canonical" href="https://irisagent.com/sd-expo-panel/watch-webinar/" />
      </Helmet>

      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Watch Panel Recording!</h1>
                <br />
                <p>
                  SupportDriven Expo Panel: Getting Started with AI for Support
                  Operations
                  <br />
                  <br />

                  Frontline support representatives have the unenviable task of responding to issues and escalations from customers in the shortest possible time. Often they start with little information about why the issue occurs and have to establish context and assess the root cause - often traced to core product related issues.
                  <br />
                  <br />
                  Product related issues are unfortunately the blind spot in Support Operations.  AI powered automated tagging and correlation to product issues as well as proactive escalation management can mitigate this.  Learn from our panelists on how they implemented the right processes and AI-powered support operation automation to help solve for this blind-spot and turn support agents into product experts that drive faster resolution times and preempt escalations.

                </p>
                {/* <img
                  src="/img/webinar-bg-2.jpeg"
                  className="img-fluid"
                  alt="Schedule-demo"
                /> */}
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <HubspotForm
                  portalId="7494256"
                  formId="6d74969d-ebb5-4144-9213-cb1304f99010"
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
